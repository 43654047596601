import React from 'react';
import Modal from '../../../components/Modal';
import img from '../../../images/city/nola_header.jpg';
import graphic from '../../../images/graphics/modal-nola.jpg';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'New Orleans, LA',
  cityModal: true,
  type: 'image',
  image: {
    src: img,
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      New Orleans is a city rich in culture and traditions, a blend of the best
      aspects of several diverse cultural and ethnic groups over its 300-year
      history. And, like many cities in the Deep South, it has deep-seated
      issues around race, equity, poverty and violence. In 2005, Hurricane
      Katrina exposed many of these issues for the world to see. A deeply
      prideful city, after years of determination and hard work, New Orleans has
      turned a corner in its recovery from that storm and is building a stronger
      and more resilient community. Yet, inequity remains. Katrina
      disproportionately impacted black residents in New Orleans, who were
      displaced and did not possess the means to rebuild and chose not to
      return.
    </p>
    <p>
      According to the U.S. Census Bureau’s 2017 population estimates, there are
      now 91,274 fewer black residents living in New Orleans compared to 2000,
      with the share of the 2017 population that was black down to 59 percent.
      Racial economic gaps are wide and persistent in New Orleans. The median
      hourly wage for white workers is $24 versus $15 for people of color. In
      New Orleans, we held focus groups where we heard the opinions of
      college-educated black and white residents and non-college-educated black
      residents. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
    <img
      src={graphic}
      alt="Map and bar charts of racial distribution in New Orleans, LA"
      className="Img-responsive"
    />
  </Modal>
);
